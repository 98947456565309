/*
 * 업무구분: 나의활동골든룰(활동현황상세)
 * 화 면 명: MSPAP309P
 * 화면설명: 활동현황상세
 * 작 성 일: 2024.04
 * 작 성 자: 이정빈
 */
<template>
  <ur-page-container class="msp" :show-title="false" title="활동상세내역" :topButton="false" ref="page">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_ClickBtnPrevious()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '활동상세내역' : '활동상세내역'}}</div>
              <!-- 2024.03.15 활동골든룰 버튼 추가 start-->
              <div slot="action">
                <mo-button shape="border" class="ns-btn-round btn-title-style-1" @click="fn_moveToMSPAP308M">활동골든룰</mo-button> 
              </div>
              <!-- 2024.03.15 활동골든룰 버튼 추가 end-->
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px" />
          </template>
          <!-- stickey 영역 -->     

          <!-- stickey 영역 -->
          <template #sticky >
            <mo-tab-box :default-idx="tab_index" solid primary class="ns-move-tab-box">
              <mo-tab-label idx="1" v-if="lv_noWeek"  @click="fn_tabIdx('1');">주차활동</mo-tab-label>
              <mo-tab-label idx="2" @click="fn_tabIdx('2');">월간활동</mo-tab-label>
            </mo-tab-box>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <!---------------------------------------------------------->
            <!------------------------주간활동--------------------------->
            <!---------------------------------------------------------->
            <ur-box-container alignV="start" componentid="" direction="column" class="pb80">
              <ur-box-container direction="column" alignV="start" v-if="tab_index === '1'">
                <ur-box-container direction="row"  alignV="start" alignH="center" class="mt30 mb30 con-area" :style="lv_buttonStyle">
                  <mo-button v-if="lv_weekPrevChk" @click="fn_getCalcWeek('prev')" class="btn-arrow-sq prev mt3"><mo-icon class="crTy-bk1" icon="msp-arrow-pre"/></mo-button>
                  <div class="week-t-head">
                    <p class="tit">{{lv_headWeekData.monthWkeely}}</p>
                    <span class="sub-tit">{{lv_headWeekData.mnStrEndYmd}}</span>
                  </div>
                  <mo-button v-if="lv_weekNextChk" @click="fn_getCalcWeek('next')" class="btn-arrow-sq next mt3"><mo-icon class="crTy-bk1" icon="msp-arrow-pre"/></mo-button>
                </ur-box-container>
                <div class="meet-list">
                  <mo-list-item :onLoad="lv_mounted = true" :expandable="list.actCnt > 0" :ref="'expItem_1' + idx" class="ns-datail-accodian" v-for="(list,idx) in iCGldnrActTypDtlSubListVO" :key="idx" >
                    <div class="list-item__contents">
                      <div class="list-item__contents__title">
                        <span>{{list.gldnrActTypNm}}</span>
                        <span class="count">{{list.actCnt}}</span>
                      </div>
                    </div>
                    <template v-slot:expand class="ns-gray-box">
                      <div class="list-item__contents">
                        <div class="result-contents-box">
                          <div class="contents-row timeline">
                            <div class="timeline-article" v-for="(list2,idx2) in list.iCGldnrActTypDtlVO" :key="idx2" >
                              <strong class="timeline-data">{{list2.actMnt+'-'+list2.actDd}}</strong>
                              <div class="round-box bgcolor-white meet-cont" v-for="(item, idx3) in list2.actTypeDtlVO" :key="idx3">
                                  <p v-if="item.actHr" class="time">{{item.actHr+':'+item.actMm}}</p>
                                  <p v-if="item.mnCustNm && list.gldnrActTypCd !== '14'" @click="fn_OpenCustInfoBs(item)" class="label-txt"><span>고객명</span><u>{{item.mnCustNm}}</u></p>
                                  <p v-if="item.mnCustNm && list.gldnrActTypCd === '14'">{{item.mnCustNm}}</p>
                                  <p v-if="item.contrNm" class="label-txt"><span>계약자명</span>{{item.contrNm}}</p>
                                  <p v-if="item.mnisdNm" class="label-txt"><span>피보험자명</span>{{item.mnisdNm}}</p>
                                  <p v-if="item.prcdNm" class="label-txt"><span>상품명</span>{{item.prcdNm}}</p>
                                  <p v-if="item.prcdNm" class="label-txt" style="font-size: 14px;color: gray;"><span></span>{{'(계약일 '+item.contYmd.substr(0,4)+'-'+item.contYmd.substr(4,2)+'-'+item.contYmd.substr(6,2)+')'}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </mo-list-item>
                </div>
              </ur-box-container>
              <!---------------------------------------------------------->
              <!------------------------월간활동--------------------------->
              <!---------------------------------------------------------->
              <ur-box-container direction="column" alignV="start" v-if="tab_index === '2'">
                <ur-box-container direction="row"  alignV="start" alignH="center" class="mt30 mb30 con-area" :style="lv_buttonMonthStyle">
                  <mo-button v-if="lv_monthPrevChk" @click="fn_getCalcMonth('prev')" class="btn-arrow-sq prev mt3"><mo-icon class="crTy-bk1" icon="msp-arrow-pre"/></mo-button>
                  <div class="week-t-head">
                    <p class="tit">{{lv_headMonthData.yearMonth}}</p>
                  </div>
                  <mo-button v-if="lv_monthNextChk" @click="fn_getCalcMonth('next')" class="btn-arrow-sq next mt3"><mo-icon class="crTy-bk1" icon="msp-arrow-pre"/></mo-button>
                </ur-box-container>
                <div class="meet-list">
                  <mo-list-item :onLoad="lv_mountedTab2 = true" :expandable="list.actCnt > 0" :ref="'expItem_2' + idx"  class="ns-datail-accodian" v-for="(list,idx) in iCGldnrActTypDtlSubListMonthVO" :key="idx" >
                    <div class="list-item__contents">
                      <div class="list-item__contents__title">
                        <span>{{list.gldnrActTypNm}}</span>
                        <span class="count">{{list.actCnt}}</span>
                      </div>
                    </div>
                    <template v-slot:expand class="ns-gray-box">
                      <div class="list-item__contents">
                        <div class="result-contents-box">
                          <div class="contents-row timeline">
                            <div class="timeline-article" v-for="(list2,idx2) in list.iCGldnrActTypDtlVO" :key="idx2" >
                              <strong class="timeline-data">{{list2.actMnt+'-'+list2.actDd}}</strong>
                              <div class="round-box bgcolor-white meet-cont" v-for="(item, idx) in list2.actTypeDtlVO" :key="idx">
                                <p v-if="item.actHr" class="time">{{item.actHr+':'+item.actMm}}</p>
                                <p v-if="item.mnCustNm && list.gldnrActTypCd !== '14'" @click="fn_OpenCustInfoBs(item)" class="label-txt"><span>고객명</span><u>{{item.mnCustNm}}</u></p>
                                <p v-if="item.mnCustNm && list.gldnrActTypCd === '14'">{{item.mnCustNm}}</p>
                                <p v-if="item.contrNm" class="label-txt"><span>계약자명</span>{{item.contrNm}}</p>
                                <p v-if="item.mnisdNm" class="label-txt"><span>피보험자명</span>{{item.mnisdNm}}</p>
                                <p v-if="item.prcdNm" class="label-txt"><span>상품명</span>{{item.prcdNm}}</p>
                                <p v-if="item.prcdNm" class="label-txt" style="font-size: 14px;color: gray;"><span></span>{{'(계약일 '+item.contYmd.substr(0,4)+'-'+item.contYmd.substr(4,2)+'-'+item.contYmd.substr(6,2)+')'}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </mo-list-item>
                </div>
              </ur-box-container>
            </ur-box-container>
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP309P",
    screenId: "MSPAP309P",
    components: {
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
     async created() {
      
      console.log('MSPAP309P route params >>>> ', this.$route.params)
      this.lv_prePageId = this.$route.params.prePageId
      if(this.$route.params.curMonthChk !== undefined && this.$route.params.preMonthChk !== undefined){
        localStorage.setItem('curMonthChk',this.$route.params.curMonthChk)
        localStorage.setItem('preMonthChk',this.$route.params.preMonthChk)
      }
      if(!this.$route.params.gldnrActrExist && this.$route.params.prePageId === 'MSPAP105M'){
        this.lv_calcMon = this.$route.params.currentMon
        this.lv_paramsPageId = this.$route.params.prePageId
        this.lv_gldnrActrExist = this.$route.params.gldnrActrExist    
        this.lv_noWeek = false      
        this.tab_index = '2'
        await this.fn_tabIdx('2')
      }else if(this.$route.params.gldnrActrExist && this.$route.params.prePageId === 'MSPAP105M'){
        this.lv_calcMon = this.$route.params.currentMon
        this.lv_paramsPageId = this.$route.params.prePageId
        this.lv_gldnrActrExist = this.$route.params.gldnrActrExist   
        if(!this.$route.params.preMonthChk && !this.$route.params.curMonthChk){
          this.lv_noWeek = false      
        }else{
          this.lv_noWeek = true
        } 
        this.tab_index = '2'
        await this.fn_curLoadData()
        await this.fn_tabIdx('2')
      }else{
        if(this.$route.params.currentMon !== undefined){
          this.lv_calcMonTab = this.$route.params.currentMon
          this.lv_calcWeek = Number(this.$route.params.currentWK)
          this.lv_index = this.$route.params.index
          this.lv_curMonthChk = this.$route.params.curMonthChk
          this.lv_preMonthChk = this.$route.params.preMonthChk
          if(!this.$route.params.preMonthChk && !this.$route.params.curMonthChk){
            this.lv_calcMon = this.fn_getDate(0)
            this.lv_noWeek = false      
            this.tab_index = '2'
            await this.fn_tabIdx('2')
          }else{
            if(!this.$route.params.curMonthChk && this.$route.params.preMonthChk){
              this.lv_calcMon = this.fn_getDate(-1)
              this.lv_index = 0
              this.lv_calcWeek = 1
            }
            this.lv_noWeek = true
            this.tab_index = '1'
            await this.fn_tabIdx('1')
            this.lv_mounted = false
          }
        }else{
          console.log("@@@@@@@@@@  custCard  Return @@@@@@@@@@@@")
          this.lv_custCardReturn = true
          if(localStorage.getItem('curMonthChk') === 'true'){
            this.lv_curMonthChk = true
          }else{
            this.lv_curMonthChk = false
          }
          if(localStorage.getItem('preMonthChk') === 'true'){
            this.lv_preMonthChk = true
          }else{
            this.lv_preMonthChk = false
          }

          if((!this.lv_curMonthChk && !this.lv_preMonthChk) || this.lv_curMonthChk){
            this.lv_noWeek = false
            if(this.lv_curMonthChk){
              this.lv_noWeek = true
            }
            this.tab_index = '2'
            this.lv_calcMon = this.fn_getDate(0)
            await this.fn_tabIdx('2')
          }else if(!this.lv_curMonthChk && this.lv_preMonthChk){
            this.lv_noWeek = true
            this.tab_index = '2'
            this.lv_calcMon = this.fn_getDate(-1)
            await this.fn_tabIdx('2')
          }
      
          this.lv_mounted = false
        }
      }
    },
    mounted () {
     // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_custCardReturn: false,
        lv_prePageId:'',
        tab_index:'1',
        lv_noWeek:true,
        lv_Params:{
          cnsltNo: '',
          stndYm: ''
        },
        lv_headWeekData: {
          monthWkeely: '',
          mnStrEndYmd: ''
        },
        lv_headMonthData: {
          yearMonth:''
        },
        lv_paramsPageId: '',
        lv_calcMonTab:'',
        lv_tab2First: false,
        lv_calcMon:'',
        lv_calcWeek:'',
        lv_preMonthChk: false,
        lv_curMonthChk: false,
        lv_index:'',
        lv_gldnrActrExist:'',
        lv_buttonStyle: '',
        lv_buttonMonthStyle:'',
        lv_weekPrevChk: true,
        lv_weekNextChk: true,
        lv_monthPrevChk: true,
        lv_monthNextChk: true,
        lv_actInptYm: '',         //활동월
        lv_wklyPlnStndw: '',      //기준주차
        lv_mnStrYmd: '',          //주차시작일
        lv_mnEndYmd: '',          //주차종료일
        lv_AcvmtItmData: [
          { key: '01', label: '굿모닝미팅(조회)' },
          { key: '02', label: '석세스미팅(귀점)' },
          { key: '03', label: '귀점후활동' },
          { key: '04', label: '필컨동의' },
          { key: '05', label: '신규필컨' },
          { key: '06', label: '통합보장설계' },
          { key: '07', label: '가입설계' },
          { key: '08', label: '청약(발행)' },
          { key: '09', label: '신계약' },
          { key: '10', label: '컨설팅' },
          { key: '11', label: '전화' },
          { key: '12', label: '문자' },
          { key: '13', label: '이메일' },
          { key: '14', label: '조회&귀점' }
        ],
        //주간활동
        iCGldnrActTypDtlListVO:[],
        iCGldnrActTypDtlSubListVO:[],
        tmpPreICGldnrActTypDtlListVO:[],
        tmpCurICGldnrActTypDtlListVO:[],

        //월간활동
        iCGldnrActTypDtlSubListMonthVO:[],
        tmpPreICGldnrActTypDtlSubListMonthVO:[],
        tmpCurICGldnrActTypDtlSubListMonthVO:[],

        lv_HeaderviewScrollCompID:'',
        
        lv_firstLoad: true,
        lv_mounted: false,
        lv_mountedTab2: false
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
    },
    watch: {
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_tabIdx
       * 설명       : tab index
      ******************************************************************************/
      async fn_tabIdx (str) {
        if (str === '1') {
          this.tab_index = '1'
          this.lv_calcMon = this.lv_calcMonTab
         
          if(!this.lv_curMonthChk && this.lv_preMonthChk){
            this.lv_calcMon = this.fn_getDate(-1)
            this.lv_index = 0
            this.lv_calcWeek = 1
          }
          await this.fn_loadWeekDataPre()
          await this.fn_loadWeekDataCur()   
          await this.fn_init()
          await this.fn_loadDtlWeekData()
        } else {
          this.tab_index = '2'
          this.lv_calcMonTab = this.lv_calcMon
          this.lv_tab2First = true

          await this.fn_loadPreMonthData()
          await this.fn_loadCurMonthData()

          if(this.lv_preMonthChk && !this.lv_curMonthChk){
            this.lv_calcMonTab = this.fn_getDate(-1)
          }

          if(this.lv_calcMonTab === this.fn_getDate(0)){
            if(this.lv_preMonthChk){
              this.lv_monthPrevChk = true
              this.lv_monthNextChk = false
              this.lv_buttonMonthStyle = 'padding-right:52px'    
            }else{
              this.lv_monthPrevChk = false
              this.lv_monthNextChk = false
              this.lv_buttonMonthStyle = ''    
            }
          }else{//this.lv_calcMonTab === this.fn_getDate(-1)
            if(this.lv_curMonthChk){
              this.lv_monthPrevChk = false
              this.lv_monthNextChk = true
              this.lv_buttonMonthStyle = 'padding-left:52px'  
            }else{
              this.lv_monthPrevChk = false
              this.lv_monthNextChk = false
              this.lv_buttonMonthStyle = ''  
            }
          }

          await this.fn_loadDtlMonthData()
        }
      },
      /******************************************************************************
       * Function명 : fn_init
       * 설명       : fn_init
      ******************************************************************************/
      fn_init(){
        if(this.lv_calcMon === this.fn_getDate(0)){
          if(this.lv_index === 0 && this.tmpCurICGldnrActTypDtlListVO.length > 1){
            if(!this.lv_preMonthChk){
              this.lv_weekPrevChk = false
              this.lv_weekNextChk = true
              this.lv_buttonStyle = 'padding-left:52px'  
            }else{
              this.lv_weekPrevChk = true
              this.lv_weekNextChk = true
              this.lv_buttonStyle = ''  
            }
          }else if(this.tmpCurICGldnrActTypDtlListVO.length === 1){
            if(!this.lv_preMonthChk){
              this.lv_weekPrevChk = false
              this.lv_weekNextChk = false
              this.lv_buttonStyle = 'padding-left:52px;padding-right:52px'  
            }else{
              this.lv_weekPrevChk = true
              this.lv_weekNextChk = false
              this.lv_buttonStyle = 'padding-right:52px'  
            }
          }else if((0 < this.lv_index) && (this.lv_index === this.tmpCurICGldnrActTypDtlListVO.length-1)){
            this.lv_weekPrevChk = true
            this.lv_weekNextChk = false
            this.lv_buttonStyle = 'padding-right:52px'  
          }else{
            this.lv_weekPrevChk = true
            this.lv_weekNextChk = true
            this.lv_buttonStyle = ''  
          }
        }else{ //this.lv_calcMon === this.fn_getDate(-1)){
          if(this.lv_index === 0 && this.tmpPreICGldnrActTypDtlListVO.length > 1){
              this.lv_weekPrevChk = false
              this.lv_weekNextChk = true
              this.lv_buttonStyle = 'padding-left:52px'  
          }else if(this.tmpPreICGldnrActTypDtlListVO.length === 1){
            if(!this.lv_curMonthChk){
              this.lv_weekPrevChk = false
              this.lv_weekNextChk = false
              this.lv_buttonStyle = 'padding-left:52px;padding-right:52px'  
            }else{
              this.lv_weekPrevChk = false
              this.lv_weekNextChk = true
              this.lv_buttonStyle = 'padding-left:52px'  
            }
          }else if(0 < this.lv_index && this.lv_index === this.tmpPreICGldnrActTypDtlListVO.length-1){
            if(!this.lv_curMonthChk){
              this.lv_weekPrevChk = true
              this.lv_weekNextChk = false
              this.lv_buttonStyle = 'padding-right:52px'  
            }else{
              this.lv_weekPrevChk = true
              this.lv_weekNextChk = true
              this.lv_buttonStyle = ''  
            }
          }
        }
      },
      /******************************************************************************
       * Function명 : fn_getCalcWeek
       * 설명       : 전주 현재주 구하기
      ******************************************************************************/
      fn_getCalcWeek(pnFlag){
        if(pnFlag === 'prev' && pnFlag !== undefined){
          this.lv_index -= 1
          if(this.lv_calcMon === this.fn_getDate(-1)){
            if(this.lv_index === 0){
              this.lv_calcWeek = Number(this.tmpPreICGldnrActTypDtlListVO[this.lv_index].wklyDgr)
              this.lv_weekPrevChk = false
              this.lv_weekNextChk = true
              this.lv_buttonStyle = 'padding-left:52px'  
            }else{
              this.lv_calcWeek = Number(this.tmpPreICGldnrActTypDtlListVO[this.lv_index].wklyDgr)
              this.lv_weekPrevChk = true
              this.lv_weekNextChk = true
              this.lv_buttonStyle = ''  
            }
          }else if(this.lv_calcMon === this.fn_getDate(0)){
            if(this.lv_index === 0){
              this.lv_calcWeek = Number(this.tmpCurICGldnrActTypDtlListVO[this.lv_index].wklyDgr)
              if(!this.lv_preMonthChk){
                this.lv_weekPrevChk = false
                this.lv_weekNextChk = true
                this.lv_buttonStyle = 'padding-left:52px'  
              }else{
                this.lv_weekPrevChk = true
                this.lv_weekNextChk = true
                this.lv_buttonStyle = ''  
              }
            }else if(this.lv_index < 0){
              this.lv_calcMon = this.fn_getDate(-1)
              this.lv_calcWeek = Number(this.tmpPreICGldnrActTypDtlListVO[this.tmpPreICGldnrActTypDtlListVO.length-1].wklyDgr)
              this.lv_index = this.tmpPreICGldnrActTypDtlListVO.length-1
              if(this.tmpPreICGldnrActTypDtlListVO.length === 1){
                this.lv_weekPrevChk = false
                this.lv_weekNextChk = true
                this.lv_buttonStyle = 'padding-left:52px'  
              }else{
                this.lv_weekPrevChk = true
                this.lv_weekNextChk = true
                this.lv_buttonStyle = ''    
              }
            }else{
              this.lv_calcWeek = Number(this.tmpCurICGldnrActTypDtlListVO[this.lv_index].wklyDgr)
              this.lv_weekPrevChk = true
              this.lv_weekNextChk = true
              this.lv_buttonStyle = ''  
            }
          }
        }else if(pnFlag === 'next' && pnFlag !== undefined){
          this.lv_index += 1
          if(this.lv_calcMon === this.fn_getDate(-1)){
            if(this.lv_index === this.tmpPreICGldnrActTypDtlListVO.length-1){
              this.lv_calcWeek = Number(this.tmpPreICGldnrActTypDtlListVO[this.lv_index].wklyDgr)
              if(!this.lv_curMonthChk){
                this.lv_weekPrevChk = true
                this.lv_weekNextChk = false
                this.lv_buttonStyle = 'padding-right:52px'  
              }else{
                this.lv_weekPrevChk = true
                this.lv_weekNextChk = true
                this.lv_buttonStyle = ''  
              } 
            }else if(this.lv_index > this.tmpPreICGldnrActTypDtlListVO.length-1){
              this.lv_calcMon = this.fn_getDate(0)
              this.lv_calcWeek = Number(this.tmpCurICGldnrActTypDtlListVO[0].wklyDgr)
              this.lv_index = 0
              if(this.tmpCurICGldnrActTypDtlListVO.length === 1){
                this.lv_weekPrevChk = true
                this.lv_weekNextChk = false
                this.lv_buttonStyle = 'padding-right:52px'  
              }else{
                this.lv_weekPrevChk = true
                this.lv_weekNextChk = true
                this.lv_buttonStyle = ''    
              }
            }else{
              this.lv_calcWeek = Number(this.tmpPreICGldnrActTypDtlListVO[this.lv_index].wklyDgr)
              this.lv_weekPrevChk = true
              this.lv_weekNextChk = true
              this.lv_buttonStyle = ''  
            }
          }else if(this.lv_calcMon === this.fn_getDate(0)){
            if(this.lv_index === this.tmpCurICGldnrActTypDtlListVO.length-1){
              this.lv_calcWeek = Number(this.tmpCurICGldnrActTypDtlListVO[this.lv_index].wklyDgr)
              this.lv_weekPrevChk = true
              this.lv_weekNextChk = false
              this.lv_buttonStyle = 'padding-right:52px'    
            }else{
              this.lv_calcWeek = Number(this.tmpCurICGldnrActTypDtlListVO[this.lv_index].wklyDgr)
              this.lv_weekPrevChk = true
              this.lv_weekNextChk = true
              this.lv_buttonStyle = ''  
            }
          }
        }
        this.fn_loadDtlWeekData()
        
        console.log(" @@@@@@@@@@@@@ lv_calcMon >>>>>>>>>>>  "+this.lv_calcMon)
        console.log(" @@@@@@@@@@@@@ lv_calcWeek >>>>>>>>>>>  "+this.lv_calcWeek)
      },
      /******************************************************************************
       * Function명 : fn_loadWeekDataPre
       * 설명       : 골든룰 전주 주간 데이터 가져오기
      ******************************************************************************/
      async fn_loadWeekDataPre(){
        this.lv_Params = {
          cnsltNo: this.getStore('userInfo').getters.getUserInfo.userEno,  //컨설턴트NO
          stndYm : this.fn_getDate(-1)
        }
        let pParams = this.lv_Params
        let lv_Vm = this
        const trnstId = 'txTSSAP09S4'
        const auth = 'S'

        await this.post(lv_Vm, pParams, trnstId, auth).then(response => {
          if (response.body !== null && response.body.iCGldnrActTypDtlListVO !== null){
            if(response.body.iCGldnrActTypDtlListVO.length > 0) {
              //console.log(" @@@@@@  response.body  >> "+response.body)

              lv_Vm.tmpPreICGldnrActTypDtlListVO = response.body.iCGldnrActTypDtlListVO
            
              lv_Vm.lv_preMonthChk = true
              if(lv_Vm.lv_custCardReturn){
                lv_Vm.lv_calcMon = lv_Vm.fn_getDate(-1)
                lv_Vm.lv_index = 0
                lv_Vm.lv_calcWeek = Number(lv_Vm.tmpPreICGldnrActTypDtlListVO[0].wklyDgr)
              }
            }else{
              lv_Vm.lv_preMonthChk = false
            }
          }else{
            lv_Vm.lv_preMonthChk = false
          }
        }).catch(function (error) {
          window.vue.error(error)
          lv_Vm.getStore('confirm').dispatch('ADD', '조회가 실패 했습니다.')
        })    
      },
      /******************************************************************************
       * Function명 : fn_loadWeekDataCur
       * 설명       : 골든룰 현재주 주간 데이터 가져오기
      ******************************************************************************/
      async fn_loadWeekDataCur(){
        this.lv_Params = {
          cnsltNo: this.getStore('userInfo').getters.getUserInfo.userEno,  //컨설턴트NO
          stndYm : this.fn_getDate(0)
        }
        let pParams = this.lv_Params
        let lv_Vm = this
        const trnstId = 'txTSSAP09S4'
        const auth = 'S'

        await this.post(lv_Vm, pParams, trnstId, auth).then(response => {
          if (response.body !== null && response.body.iCGldnrActTypDtlListVO !== null){
            if(response.body.iCGldnrActTypDtlListVO.length > 0) {
              //console.log(" @@@@@@  response.body  >> "+response.body)

              lv_Vm.tmpCurICGldnrActTypDtlListVO = response.body.iCGldnrActTypDtlListVO

              lv_Vm.lv_curMonthChk = true
              if(lv_Vm.lv_custCardReturn){
                lv_Vm.lv_calcMon = lv_Vm.fn_getDate(0)
                lv_Vm.lv_index = 0
                lv_Vm.lv_calcWeek = Number(lv_Vm.tmpCurICGldnrActTypDtlListVO[0].wklyDgr)
              }
            }else{
              lv_Vm.lv_curMonthChk = false
            }
          }else{
            lv_Vm.lv_curMonthChk = false
          }
        }).catch(function (error) {
          window.vue.error(error)
          lv_Vm.getStore('confirm').dispatch('ADD', '조회가 실패 했습니다.')
        })          
      },
      /******************************************************************************
       * Function명 : fn_loadDtlWeekData
       * 설명       : 골든룰 주간 데이터 가져오기
      ******************************************************************************/
      fn_loadDtlWeekData(){
        this.iCGldnrActTypDtlListVO = []
        
        if(this.lv_preMonthChk && !this.lv_curMonthChk){
          this.iCGldnrActTypDtlListVO = _.cloneDeep(this.tmpPreICGldnrActTypDtlListVO)
        }else if(!this.lv_preMonthChk && this.lv_curMonthChk){ 
          this.iCGldnrActTypDtlListVO = _.cloneDeep(this.tmpCurICGldnrActTypDtlListVO)
        }else if(this.lv_preMonthChk && this.lv_curMonthChk){ 
          if(this.lv_calcMon === this.fn_getDate(0)){
            this.iCGldnrActTypDtlListVO = _.cloneDeep(this.tmpCurICGldnrActTypDtlListVO)
          }else{
            this.iCGldnrActTypDtlListVO = _.cloneDeep(this.tmpPreICGldnrActTypDtlListVO)
          }
        }
        if(this.lv_calcWeek === Number(this.iCGldnrActTypDtlListVO[this.iCGldnrActTypDtlListVO.length-1].wklyDgr)){
          this.fn_changeWk(this.iCGldnrActTypDtlListVO.length-1)
        }else if(this.lv_calcWeek === Number(this.iCGldnrActTypDtlListVO[0].wklyDgr)){
          this.fn_changeWk(0)
        }else{
          for(let i=0; i < this.iCGldnrActTypDtlListVO.length; i++){
            if(Number(this.iCGldnrActTypDtlListVO[i].wklyDgr) === this.lv_calcWeek){
              this.fn_changeWk(i)
            }
          }
        }
      },
      /******************************************************************************
       * Function명 : fn_changeWk
       * 설명       : 골든룰 주간 전환
      ******************************************************************************/
      fn_changeWk(idx){
        let tempList = []
        this.iCGldnrActTypDtlSubListVO = []
        
        // this.lv_actInptYm =  moment(this.iCGldnrActTypDtlListVO[idx].gldnrMnStrYmd).format('MM')
        this.lv_actInptYm = this.lv_calcMon.substr(5,6)
        this.lv_wklyPlnStndw = this.iCGldnrActTypDtlListVO[idx].wklyDgr
        
        this.lv_mnStrYmd = moment(this.iCGldnrActTypDtlListVO[idx].gldnrMnStrYmd).format('YYYY-MM-DD')
        this.lv_mnEndYmd = moment(this.iCGldnrActTypDtlListVO[idx].gldnrMnEndYmd).format('YYYY-MM-DD')

        this.lv_headWeekData = {
          monthWkeely: this.lv_actInptYm+'월 '+this.lv_wklyPlnStndw+'주차',  //월주차
          mnStrEndYmd: this.lv_mnStrYmd+' ~ '+this.lv_mnEndYmd
        }
        if(this.lv_mnStrYmd === 'Invalid date'){
          this.lv_headWeekData.mnStrEndYmd = ' '
        }

        for(let i=0; i < this.iCGldnrActTypDtlListVO[idx].iCGldnrActTypDtlSubListVO.length; i++){
          this.iCGldnrActTypDtlSubListVO[i] = this.iCGldnrActTypDtlListVO[idx].iCGldnrActTypDtlSubListVO[i]
          let tmpActCnt = this.iCGldnrActTypDtlSubListVO[i].actCnt 
          this.iCGldnrActTypDtlSubListVO[i].gldnrActTypNm = this.lv_AcvmtItmData.find(x => x.key === this.iCGldnrActTypDtlSubListVO[i].gldnrActTypCd).label
          tempList = _.cloneDeep(this.iCGldnrActTypDtlSubListVO[i].iCGldnrActTypDtlVO.reduce(function (acc, obj) {
            let key = Number(obj['actDd'])
          if(!acc[key]){
            acc[key] = []
          }
          acc[key].push(obj)
          return (acc)
          },{}))

          let cnt = Object.keys(tempList).length
          this.iCGldnrActTypDtlSubListVO[i].actCnt = tmpActCnt
          this.iCGldnrActTypDtlSubListVO[i].iCGldnrActTypDtlVO.length = cnt
          let cntVal = 0
          loop : for(let j=0; j < cnt; j++){
            for(let k=cntVal; k <= 31; k++){
              if(tempList[k] !== undefined){
                this.iCGldnrActTypDtlSubListVO[i].iCGldnrActTypDtlVO[j] = { actYr: '',actMnt: '',actDd: '',actTypeDtlVO:[]}
                this.iCGldnrActTypDtlSubListVO[i].iCGldnrActTypDtlVO[j].actYr = tempList[k][0].actYr
                this.iCGldnrActTypDtlSubListVO[i].iCGldnrActTypDtlVO[j].actMnt = tempList[k][0].actMnt
                this.iCGldnrActTypDtlSubListVO[i].iCGldnrActTypDtlVO[j].actDd = tempList[k][0].actDd
                this.iCGldnrActTypDtlSubListVO[i].iCGldnrActTypDtlVO[j].actTypeDtlVO = tempList[k]

                cntVal = k +1
                continue loop
              }
            }
          }
        }
        if(this.lv_mounted){
          for(let i=0 ; i<this.iCGldnrActTypDtlSubListVO.length; i++){
            if(this.$refs['expItem_1' + i][0] !== undefined){
              this.$refs['expItem_1' + i][0].isExpand = false
            }
          }  
        }
        this.lv_firstLoad = false
      },
      /******************************************************************************
       * Function명 : fn_curLoadData
       * 설명       : 골든룰 데이터 가져오기
      ******************************************************************************/
      async fn_curLoadData(){
        this.lv_Params = {
          cnsltNo : this.getStore('userInfo').getters.getUserInfo.userEno, 
          stndYm : this.fn_getDate(0)
        }
        
        let pParams = this.lv_Params
        var lv_Vm = this
        const trnstId = 'txTSSAP09S3'
        const auth = 'S'

        await this.post(lv_Vm, pParams, trnstId, auth).then(response => {
          if (response.body !== null && response.body.iCGldnrActWeklySmupVO !== null){
            if(response.body.iCGldnrActWeklySmupVO.length > 0) {
              //console.log(" @@@@@@  response.body this.fn_getDate(0) >> "+response.body.iCGldnrActWeklySmupVO)
              lv_Vm.tmpCurICGldnrActWeklySmupVO = response.body.iCGldnrActWeklySmupVO
                
              for(let i=0; i < lv_Vm.tmpCurICGldnrActWeklySmupVO.length; i++){
                if(lv_Vm.tmpCurICGldnrActWeklySmupVO[i].curYn === 'Y'){
                    lv_Vm.lv_index = i
                    lv_Vm.lv_calcWeek = Number(lv_Vm.tmpCurICGldnrActWeklySmupVO[i].weklyDgr)
                    break
                }else{
                  if(lv_Vm.tmpCurICGldnrActWeklySmupVO[i].acvmtYn !== '5'){
                    lv_Vm.lv_index = i
                    lv_Vm.lv_calcWeek = Number(lv_Vm.tmpCurICGldnrActWeklySmupVO[i].weklyDgr)
                  }
                }
              }
            }
          }
        }).catch(function (error) {
          window.vue.error(error)
          lv_Vm.getStore('confirm').dispatch('ADD', '조회 실패 했습니다.')
        })
      },
      /******************************************************************************
       * Function명 : fn_getCalcMonth
       * 설명       : 전월 당월 구하기
      ******************************************************************************/
      fn_getCalcMonth(pnFlag){
        if(pnFlag === 'prev' && pnFlag !== undefined){
          this.lv_calcMonTab = this.fn_getDate(-1)
          this.fn_loadDtlMonthData()
          
          this.lv_monthPrevChk = false
          this.lv_monthNextChk = true
          this.lv_buttonMonthStyle = 'padding-left:52px'  

        }else if(pnFlag === 'next' && pnFlag !== undefined){
          this.lv_calcMonTab = this.fn_getDate(0)
          this.fn_loadDtlMonthData()
          
          this.lv_monthPrevChk = true
          this.lv_monthNextChk = false
          this.lv_buttonMonthStyle = 'padding-right:52px'  
        }
        console.log(" @@@@@@@@@@@@@ lv_calcMonTab >>>>>>>>>>>  "+this.lv_calcMonTab)
      },    
      /******************************************************************************
       * Function명 : fn_loadPreMonthData
       * 설명       : 골든룰 전월 데이터 가져오기
      ******************************************************************************/
      async fn_loadPreMonthData(){
        this.lv_Params = {
          cnsltNo: this.getStore('userInfo').getters.getUserInfo.userEno,  //컨설턴트NO
          stndYm: this.fn_getDate(-1)
        }
        let pParams = this.lv_Params
        let lv_Vm = this
        const trnstId = 'txTSSAP09S5'
        const auth = 'S'

        await this.post(lv_Vm, pParams, trnstId, auth).then(response => {
          if (response.body !== null && response.body.iCGldnrActTypDtlSubListVO !== null){
            if(response.body.iCGldnrActTypDtlSubListVO.length > 0) {
              //console.log(" @@@@@@  response.body  >> "+response.body)

              lv_Vm.tmpPreICGldnrActTypDtlSubListMonthVO = response.body.iCGldnrActTypDtlSubListVO

              lv_Vm.lv_preMonthChk = true
            }else{
              lv_Vm.lv_preMonthChk = false
            }
          }else{
            lv_Vm.lv_preMonthChk = false
          }
        }).catch(function (error) {
          window.vue.error(error)
          lv_Vm.getStore('confirm').dispatch('ADD', '조회 실패 했습니다')
        })
      },  
      /******************************************************************************
       * Function명 : fn_loadCurMonthData
       * 설명       : 골든룰 당월 데이터 가져오기
      ******************************************************************************/
      async fn_loadCurMonthData(){
        this.lv_Params = {
          cnsltNo: this.getStore('userInfo').getters.getUserInfo.userEno,  //컨설턴트NO
          stndYm: this.fn_getDate(0)
        }
        let pParams = this.lv_Params
        let lv_Vm = this
        const trnstId = 'txTSSAP09S5'
        const auth = 'S'

        await this.post(lv_Vm, pParams, trnstId, auth).then(response => {
          if (response.body !== null && response.body.iCGldnrActTypDtlSubListVO !== null){
            if(response.body.iCGldnrActTypDtlSubListVO.length > 0) {
              //console.log(" @@@@@@  response.body  >> "+response.body)

              lv_Vm.tmpCurICGldnrActTypDtlSubListMonthVO = response.body.iCGldnrActTypDtlSubListVO
              
              lv_Vm.lv_curMonthChk = true
            }else{
              lv_Vm.lv_curMonthChk = false
            }
          }else{
            lv_Vm.lv_curMonthChk = false
          }
        }).catch(function (error) {
          window.vue.error(error)
          lv_Vm.getStore('confirm').dispatch('ADD', '조회 실패 했습니다')
        })
      },
      /******************************************************************************
       * Function명 : fn_loadDtlMonthData
       * 설명       : 골든룰 월간 데이터 가져오기
      ******************************************************************************/
      fn_loadDtlMonthData(){
        if(this.lv_calcMonTab === this.fn_getDate(0)){
          this.iCGldnrActTypDtlSubListMonthVO = _.cloneDeep(this.tmpCurICGldnrActTypDtlSubListMonthVO)
        }else{
          this.iCGldnrActTypDtlSubListMonthVO = _.cloneDeep(this.tmpPreICGldnrActTypDtlSubListMonthVO)
        }

        this.fn_changeMonth()
      },
      /******************************************************************************
       * Function명 : fn_changeMonth
       * 설명       : 골든룰 월간 전환
      ******************************************************************************/
      fn_changeMonth(){
        let tempList = []

        this.lv_headMonthData = {
          yearMonth: this.lv_calcMonTab.substr(0,4)+'년 '+this.lv_calcMonTab.substr(4,5)+'월'
        }
        for(let i=0; i < this.iCGldnrActTypDtlSubListMonthVO.length; i++){
          let tmpActCnt = this.iCGldnrActTypDtlSubListMonthVO[i].actCnt 
          this.iCGldnrActTypDtlSubListMonthVO[i].gldnrActTypNm = this.lv_AcvmtItmData.find(x => x.key === this.iCGldnrActTypDtlSubListMonthVO[i].gldnrActTypCd).label
          tempList = _.cloneDeep(this.iCGldnrActTypDtlSubListMonthVO[i].iCGldnrActTypDtlVO.reduce(function (acc, obj) {
            let key = Number(obj['actDd'])
            if(key === 0){
              return 0
            }
          if(!acc[key]){
            acc[key] = []
          }
          acc[key].push(obj)
          return (acc)
          },{}))

          let cnt = Object.keys(tempList).length
          this.iCGldnrActTypDtlSubListMonthVO[i].actCnt = tmpActCnt
          this.iCGldnrActTypDtlSubListMonthVO[i].iCGldnrActTypDtlVO.length = cnt
          let cntVal = 0
          loop : for(let j=0; j < cnt; j++){
            for(let k=cntVal; k <= 31; k++){
              if(tempList[k] !== undefined){
                this.iCGldnrActTypDtlSubListMonthVO[i].iCGldnrActTypDtlVO[j] = { actYr: '',actMnt: '',actDd: '',actTypeDtlVO:[]}
                this.iCGldnrActTypDtlSubListMonthVO[i].iCGldnrActTypDtlVO[j].actYr = tempList[k][0].actYr
                this.iCGldnrActTypDtlSubListMonthVO[i].iCGldnrActTypDtlVO[j].actMnt = tempList[k][0].actMnt
                this.iCGldnrActTypDtlSubListMonthVO[i].iCGldnrActTypDtlVO[j].actDd = tempList[k][0].actDd
                this.iCGldnrActTypDtlSubListMonthVO[i].iCGldnrActTypDtlVO[j].actTypeDtlVO = tempList[k]

                cntVal = k +1
                continue loop
              }
            }
          }
        }
        if(this.lv_mountedTab2){
          for(let i=0 ; i<this.iCGldnrActTypDtlSubListMonthVO.length; i++){
            if(this.$refs['expItem_2' + i][0] !== undefined){
              this.$refs['expItem_2' + i][0].isExpand = false
            }
          }
        }
        this.lv_tab2First = false
      },      
      /******************************************************************************
       * Function명 : fn_getDate
       * 설명       : 월 계산
      ******************************************************************************/
      fn_getDate(val){
        let d = new Date()
        if(val !== 0){
          let lastDay = (new Date(d.getFullYear(), d.getMonth(),0)).getDate()
          if(d.getDate() > lastDay){
            d.setDate(lastDay)
          }
          d.setMonth(d.getMonth() -1)
        }
        return d.getFullYear() + ('0'+(d.getMonth()+1)).slice(-2)
      },
      /******************************************************************************
       * Function명 : fn_ClickBtnPrevious
       * 설명       : 뒤로가기
      ******************************************************************************/
      fn_ClickBtnPrevious () {
        this.fn_moveToMSPAP308M()
      },
      /******************************************************************************
       * Function명 : fn_moveToMSPAP308M
       * 설명       : 활동골든룰 페이지로 이동
      ******************************************************************************/
      fn_moveToMSPAP308M(){
        console.log(" @@@@@@@@@@@  MSPAP308M >> ")
        let flagVal = 'N'
        
        if(!this.lv_gldnrActrExist && this.lv_paramsPageId === 'MSPAP105M'){
          this.lv_calcMon = this.lv_calcMonTab
        }

        if(this.lv_calcMon === this.fn_getDate(0) || this.lv_calcMonTab === this.fn_getDate(0)){
          flagVal = 'Y'
        }
        this.$router.push({name: 'MSPAP308M', params: {flagVal: flagVal, prePageId:this.lv_prePageId, gldnrActrExist:this.lv_gldnrActrExist}})
      },
      /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
      fn_OpenCustInfoBs (item) {
        let lv_Vm = this
        let cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId
        let nmUiObject = {}
        
        nmUiObject = {
          chnlCustId : item.gldnrObjFileId, //고객채널 ID
          parentId   : 'MSPAP309M', //화면ID+
          cnsltNo : cnsltNo,
          custNm : item.mnCustNm
        }

        lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
          //Close 콜백 처리
          return
        })
      }
    }
  };
</script>